import axios from 'axios'
import { toQueryString } from 'utils/objects'

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API
})

export const axiosInstanceChat = axios.create({
  baseURL: process.env.REACT_APP_CHAT_API
})

export const serverInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL
})

export const axiosInstanceWebPage = axios.create({
  baseURL: process.env.REACT_APP_API_AUTH_URL
})

export const axiosInstanceGeneralConfig = axios.create({
  baseURL: process.env.REACT_APP_API_GENERAL_CONFIG_URL
})

export const axiosInstanceReservation = axios.create({
  baseURL: process.env.REACT_APP_API_RESERVATIONS_URL
})

export const axiosInstanceProducts = axios.create({
  baseURL: process.env.REACT_APP_API_PRODUCTS_URL
})

export const axiosInstanceProductsLocal = axios.create({
  baseURL: process.env.REACT_APP_API_PRODUCTS_URL_LOCAL
})

export const axiosInstanceNotifications = axios.create({
  baseURL: process.env.REACT_APP_API_NOTIFICATIONS_URL
})

export const axiosInstancePayments = axios.create({
  baseURL: process.env.REACT_APP_API_PAYMENTS_URL
})

export const axiosInstanceEventos = axios.create({
  baseURL: process.env.REACT_APP_API_EVENTS_URL
})

export const axiosInstanceDevNotification = axios.create({
  baseURL: 'https://dev.notification.bodytech.co/api/'
})

export const axiosInstanceCRM = axios.create({
  baseURL:
    process.env.REACT_APP_API_CRM_URL || 'https://dev.crm.bodytech.co/api/'
})

export const axiosInstanceCRMV2 = axios.create({
  baseURL:
    process.env.REACT_APP_API_CRM_V2_URL
})

export const axiosInstanceHarvest = axios.create({
  baseURL: process.env.REACT_APP_API_HARVEST_URL
})

export const axiosInstanceAccessControl = axios.create({
  baseURL: process.env.REACT_APP_API_ACCESS_URL
})

export const axiosInstanceReferrals = axios.create({
  baseURL: process.env.REACT_APP_API_REFERRALS_URL
})

axiosInstanceAccessControl.interceptors.request.use(
  (config) => {
    const userToken = localStorage.getItem('acess_token') || ('refresh_token')
    if (!userToken) {
      localStorage.clear()
    }
    const currentUser = localStorage.getItem('access_token') || ''
    if (currentUser) {
      config.headers.Authorization = `Bearer ${currentUser}`
      config.headers['x-bodytech-company'] =
        localStorage.getItem('company_id') ||
        '006629fa-6aae-4c02-b28f-5264387199bb'
      config.headers['x-bodytech-brand'] =
        localStorage.getItem('brand_id') || 1
      config.headers['x-bodytech-organization'] =
        localStorage.getItem('organization_id')
      config.headers['X-Bodytech-Virtual'] =
        localStorage.getItem('is_virtual') || 0
    }
    return config
  },
  (error) => Promise.reject(error)
)

axiosInstance.interceptors.request.use(
  (config) => {
    const userToken = localStorage.getItem('acess_token') || ('refresh_token')
    if (!userToken) {
      localStorage.removeItem('acess_token' && 'refresh_token')
    }

    const currentUser = localStorage.getItem('access_token') || ''
    if (currentUser) {
      config.headers.Authorization = `Bearer ${currentUser}`
      config.headers['x-bodytech-company'] =
        localStorage.getItem('company_id') ||
        '006629fa-6aae-4c02-b28f-5264387199bb'
      config.headers['x-bodytech-brand'] =
        localStorage.getItem('brand_id') || 1
      config.headers['x-bodytech-organization'] =
        localStorage.getItem('organization_id')
      config.headers['X-Bodytech-Virtual'] =
        localStorage.getItem('is_virtual') || 0
    }
    return config
  },
  (error) => Promise.reject(error)
)

axiosInstanceWebPage.interceptors.request.use(
  (config) => {
    try {
      config.headers['x-bodytech-recaptcha'] = sessionStorage.getItem('recaptcha') ?? ''
    } catch (err) {}
    const userToken = localStorage.getItem('acess_token') || ('refresh_token')
    if (!userToken) {
      localStorage.removeItem('acess_token' && 'refresh_token')
    }
    const currentUser = localStorage.getItem('access_token') || ''
    if (currentUser) {
      config.headers.Authorization = `Bearer ${currentUser}`
      config.headers['x-bodytech-company'] =
        localStorage.getItem('company_id') ||
        '006629fa-6aae-4c02-b28f-5264387199bb'
      config.headers['x-bodytech-brand'] =
        localStorage.getItem('brand_id') || 1
      config.headers['x-bodytech-organization'] =
        localStorage.getItem('organization_id')
      config.headers['X-Bodytech-Virtual'] =
        localStorage.getItem('is_virtual') || 0
    }
    return config
  },
  (error) => Promise.reject(error)
)

axiosInstanceGeneralConfig.interceptors.request.use(
  (config) => {
    const userToken = localStorage.getItem('acess_token') || ('refresh_token')
    if (!userToken) {
      localStorage.removeItem('acess_token' && 'refresh_token')
    }
    const currentUser = localStorage.getItem('access_token') || ''

    if (currentUser) {
      config.headers.Authorization = `Bearer ${currentUser}`
      config.headers['x-bodytech-company'] =
        localStorage.getItem('company_id') ||
        '006629fa-6aae-4c02-b28f-5264387199bb'
      config.headers['x-bodytech-brand'] =
        localStorage.getItem('brand_id') || 1
      config.headers['x-bodytech-organization'] =
        localStorage.getItem('organization_id')
      config.headers['X-Bodytech-Virtual'] =
        localStorage.getItem('is_virtual') || 0
    }
    return config
  },
  (error) => Promise.reject(error)
)

axiosInstanceReservation.interceptors.request.use(
  (config) => {
    const userToken = localStorage.getItem('acess_token') || ('refresh_token')
    if (!userToken) {
      localStorage.removeItem('acess_token' && 'refresh_token')
    }
    const currentUser = localStorage.getItem('access_token') || ''

    if (currentUser) {
      config.headers.Authorization = `Bearer ${currentUser}`
      config.headers['x-bodytech-company'] =
        localStorage.getItem('company_id') ||
        '006629fa-6aae-4c02-b28f-5264387199bb'
      config.headers['x-bodytech-brand'] =
        localStorage.getItem('brand_id') || 1
      config.headers['x-bodytech-organization'] =
        localStorage.getItem('organization_id')
      config.headers['X-Bodytech-Virtual'] =
        localStorage.getItem('is_virtual') || 0
    }

    if (config.headers['X-Bodytech-Virtual-Option']) {
      config.headers['X-Bodytech-Virtual'] =
        config.headers['X-Bodytech-Virtual-Option'] || 0
    }
    return config
  },
  (error) => Promise.reject(error)
)

axiosInstanceProducts.interceptors.request.use(
  (config) => {
    const userToken = localStorage.getItem('acess_token') || ('refresh_token')
    if (!userToken) {
      localStorage.removeItem('acess_token' && 'refresh_token')
    }
    const currentUser = localStorage.getItem('access_token') || ''
    if (currentUser) {
      config.headers.Authorization = `Bearer ${currentUser}`
      config.headers['x-bodytech-company'] =
        localStorage.getItem('company_id') ||
        '006629fa-6aae-4c02-b28f-5264387199bb'
      config.headers['x-bodytech-brand'] =
        localStorage.getItem('brand_id') || 1
      config.headers['x-bodytech-organization'] =
        localStorage.getItem('organization_id')
      config.headers['X-Bodytech-Virtual'] =
        localStorage.getItem('is_virtual') || 0
    }
    return config
  },
  (error) => Promise.reject(error)
)

axiosInstanceReferrals.interceptors.request.use(
  (config) => {
    const userToken = localStorage.getItem('acess_token') || ('refresh_token')
    if (!userToken) {
      localStorage.removeItem('acess_token' && 'refresh_token')
    }
    const currentUser = localStorage.getItem('access_token') || ''
    if (currentUser) {
      config.headers.Authorization = `Bearer ${currentUser}`
      config.headers['x-bodytech-company'] =
        localStorage.getItem('company_id') ||
        '006629fa-6aae-4c02-b28f-5264387199bb'
      config.headers['x-bodytech-brand'] =
        localStorage.getItem('brand_id') || 1
      config.headers['x-bodytech-organization'] =
        localStorage.getItem('organization_id')
      config.headers['X-Bodytech-Virtual'] =
        localStorage.getItem('is_virtual') || 0
    }
    return config
  },
  (error) => Promise.reject(error)
)

axiosInstanceProductsLocal.interceptors.request.use(
  (config) => {
    const userToken = localStorage.getItem("acess_token") || ("refresh_token");
    if(!userToken){
      localStorage.removeItem("acess_token" && "refresh_token");
    }
    const currentUser = localStorage.getItem("access_token") || "";
    if (currentUser) {
      config.headers["Authorization"] = `Bearer ${currentUser}`;
      config.headers["x-bodytech-company"] =
        localStorage.getItem("company_id") ||
        "006629fa-6aae-4c02-b28f-5264387199bb";
      config.headers["x-bodytech-brand"] =
        localStorage.getItem("brand_id") || 1;
      config.headers["x-bodytech-organization"] =
        localStorage.getItem("organization_id");
      config.headers["X-Bodytech-Virtual"] =
        localStorage.getItem("is_virtual") || 0;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstanceNotifications.interceptors.request.use(
  (config) => {
    const userToken = localStorage.getItem('acess_token') || ('refresh_token')
    if (!userToken) {
      localStorage.removeItem('acess_token' && 'refresh_token')
    }
    const currentUser = localStorage.getItem('access_token') || ''
    if (currentUser) {
      config.headers.Authorization = `Bearer ${currentUser}`
      config.headers['x-bodytech-company'] =
        localStorage.getItem('company_id') ||
        '006629fa-6aae-4c02-b28f-5264387199bb'
      config.headers['x-bodytech-brand'] =
        localStorage.getItem('brand_id') || 1
      config.headers['x-bodytech-organization'] =
        localStorage.getItem('organization_id')
      config.headers['X-Bodytech-Virtual'] =
        localStorage.getItem('is_virtual') || 0
    }
    return config
  },
  (error) => Promise.reject(error)
)

axiosInstancePayments.interceptors.request.use(
  (config) => {
    const userToken = localStorage.getItem('acess_token') || ('refresh_token')
    if (!userToken) {
      localStorage.removeItem('acess_token' && 'refresh_token')
    }
    const currentUser = localStorage.getItem('access_token') || ''

    if (currentUser) {
      config.headers.Authorization = `Bearer ${currentUser}`
      config.headers['x-bodytech-company'] =
        localStorage.getItem('company_id') ||
        '006629fa-6aae-4c02-b28f-5264387199bb'
      config.headers['x-bodytech-brand'] =
        localStorage.getItem('brand_id') || 1
      config.headers['x-bodytech-organization'] =
        localStorage.getItem('organization_id')
      config.headers['X-Bodytech-Virtual'] =
        localStorage.getItem('is_virtual') || 0
    }
    return config
  },
  (error) => Promise.reject(error)
)

axiosInstanceHarvest.interceptors.request.use(
  (config) => {
    const userToken = localStorage.getItem('acess_token') || ('refresh_token')
    if (!userToken) {
      localStorage.removeItem('acess_token' && 'refresh_token')
    }
    const currentUser = localStorage.getItem('access_token') || ''
    if (currentUser) {
      config.headers.Authorization = `Bearer ${currentUser}`
      config.headers['x-bodytech-company'] =
        localStorage.getItem('company_id') ||
        '006629fa-6aae-4c02-b28f-5264387199bb'
      config.headers['x-bodytech-brand'] =
        localStorage.getItem('brand_id') || 1
      config.headers['x-bodytech-organization'] =
        localStorage.getItem('organization_id')
      config.headers['X-Bodytech-Virtual'] =
        localStorage.getItem('is_virtual') || 0
    }
    return config
  },
  (error) => Promise.reject(error)
)

axiosInstanceWebPage.interceptors.response.use(
  (res) => res,
  (err) => {
    return new Promise((resolve, reject) => {
      const originalReq = err.config
      if (err.response && err.response.data) {
        const messageStatus = err.response.data?.status
        if (
          messageStatus === 'expired_token' ||
          messageStatus === 'invalid_request' ||
          messageStatus === 'invalid_token'
        ) {
          const res = fetch(`${process.env.REACT_APP_API_AUTH_URL}oauth/token`, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrer: 'no-referrer',
            body: JSON.stringify({
              grant_type:
                messageStatus === 'expired_token'
                  ? 'refresh_token'
                  : 'client_credentials',
              client_id: process.env.REACT_APP_CLIENT_ID_LOGIN,
              client_secret: process.env.REACT_APP_CLIENT_SECRET_LOGIN
            })
          })
            .then((res) => res.json())
            .then((res) => {
              localStorage.setItem('access_token', res.access_token)
              if (res.refresh_token) {
                localStorage.setItem('refresh_token', res.refresh_token)
              } if (
                messageStatus === 'expired_token' ||
                messageStatus === 'invalid_request' ||
                messageStatus === 'invalid_token'
              ) {
                window.location.reload(true)
              }
              originalReq.headers.Authorization = `Bearer ${res.access_token}`
              return axiosInstanceWebPage(originalReq)
            })
          resolve(res)
        }
        reject(err.response.data)
      } else {
        reject(err.response)
      }
    })
  }
)

axiosInstance.interceptors.response.use(
  (res) => res,
  (err) => {
    return new Promise((resolve, reject) => {
      const originalReq = err.config
      if (err.response && err.response.data) {
        const messageStatus = err.response.data?.status
        if (
          messageStatus === 'expired_token' ||
          messageStatus === 'invalid_request' ||
          messageStatus === 'invalid_token'
        ) {
          const res = fetch(`${process.env.REACT_APP_API_AUTH_URL}oauth/token`, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrer: 'no-referrer',
            body: JSON.stringify({
              grant_type:
                messageStatus === 'expired_token'
                  ? 'refresh_token'
                  : 'client_credentials',
              client_id: process.env.REACT_APP_CLIENT_ID_LOGIN,
              client_secret: process.env.REACT_APP_CLIENT_SECRET_LOGIN,
              refresh_token: localStorage.getItem('refresh_token')
            })
          })
            .then((res) => res.json())
            .then((res) => {
              localStorage.setItem('access_token', res.access_token)
              if (res.refresh_token) {
                localStorage.setItem('refresh_token', res.refresh_token)
              } if (
                messageStatus === 'expired_token' ||
                messageStatus === 'invalid_request' ||
                messageStatus === 'invalid_token'
              ) {
                window.location.reload(true)
              }
              originalReq.headers.Authorization = `Bearer ${res.access_token}`
              return axiosInstance(originalReq)
            })
          resolve(res)
        }
        reject(err.response.data)
      } else {
        reject(err.response)
      }
    })
  }
)

axiosInstanceReservation.interceptors.response.use(
  (res) => res,
  (err) => {
    return new Promise((resolve, reject) => {
      const originalReq = err.config
      if (err.response && err.response.data) {
        const messageStatus = err.response.data?.status
        if (
          messageStatus === 'expired_token' ||
          messageStatus === 'invalid_request' ||
          messageStatus === 'invalid_token'
        ) {
          const res = fetch(`${process.env.REACT_APP_API_AUTH_URL}oauth/token`, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrer: 'no-referrer',
            body: JSON.stringify({
              grant_type:
                messageStatus === 'expired_token'
                  ? 'refresh_token'
                  : 'client_credentials',
              client_id: process.env.REACT_APP_CLIENT_ID_LOGIN,
              client_secret: process.env.REACT_APP_CLIENT_SECRET_LOGIN,
              refresh_token: localStorage.getItem('refresh_token')
            })
          })
            .then((res) => res.json())
            .then((res) => {
              localStorage.setItem('access_token', res.access_token)
              if (res.refresh_token) {
                localStorage.setItem('refresh_token', res.refresh_token)
              } if (messageStatus === 'expired_token' ||
              messageStatus === 'invalid_request' ||
              messageStatus === 'invalid_token') {
                window.location.reload(true)
              }
              originalReq.headers.Authorization = `Bearer ${res.access_token}`
              return axiosInstance(originalReq)
            })
          resolve(res)
        }
        reject(err.response.data)
      } else {
        reject(err.response)
      }
    })
  }
)

axiosInstanceProducts.interceptors.response.use(
  (res) => res,
  (err) => {
    return new Promise((resolve, reject) => {
      reject(err.response.data)
    })
  }
)

axiosInstanceNotifications.interceptors.response.use(
  (res) => res,
  (err) => {
    return new Promise((resolve, reject) => {
      reject(err.response.data)
    })
  }
)

axiosInstanceGeneralConfig.interceptors.response.use(
  (res) => res,
  (err) => {
    return new Promise((resolve, reject) => {
      const originalReq = err.config
      if (err.response && err.response.data) {
        const messageStatus = err.response.data?.status
        if (
          messageStatus === 'expired_token' ||
          messageStatus === 'invalid_request' ||
          messageStatus === 'invalid_token'
        ) {
          const res = fetch(`${process.env.REACT_APP_API_AUTH_URL}oauth/token`, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrer: 'no-referrer',
            body: JSON.stringify({
              grant_type:
                messageStatus === 'expired_token'
                  ? 'refresh_token'
                  : 'client_credentials',
              client_id: process.env.REACT_APP_CLIENT_ID_LOGIN,
              client_secret: process.env.REACT_APP_CLIENT_SECRET_LOGIN,
              refresh_token: localStorage.getItem('refresh_token')
            })
          })
            .then((res) => res.json())
            .then((res) => {
              localStorage.setItem('access_token', res.access_token)
              if (res.refresh_token) {
                localStorage.setItem('refresh_token', res.refresh_token)
              } if (
                messageStatus === 'expired_token' ||
                messageStatus === 'invalid_request' ||
                messageStatus === 'invalid_token'
              ) {
                window.location.reload(true)
              }
              originalReq.headers.Authorization = `Bearer ${res.access_token}`
              return axiosInstance(originalReq)
            })
          resolve(res)
        }
        reject(err.response.data)
      } else {
        reject(err.response)
      }
    })
  }
)

axiosInstanceCRM.interceptors.request.use(
  (config) => {
    const userToken = localStorage.getItem('acess_token') || ('refresh_token')
    if (!userToken) {
      localStorage.removeItem('acess_token' && 'refresh_token')
    }
    const currentUser = localStorage.getItem('access_token') || ''

    if (currentUser) {
      config.headers.Authorization = `Bearer ${currentUser}`
      config.headers['x-bodytech-company'] =
        localStorage.getItem('company_id') ||
        '006629fa-6aae-4c02-b28f-5264387199bb'
      config.headers['x-bodytech-brand'] =
        localStorage.getItem('brand_id') || 1
      config.headers['x-bodytech-organization'] =
        localStorage.getItem('organization_id')
      config.headers['X-Bodytech-Virtual'] =
        localStorage.getItem('is_virtual') || 0
    }
    return config
  },
  (error) => Promise.reject(error)
)

axiosInstanceCRM.interceptors.response.use(
  (res) => res,
  (err) => {
    return new Promise((resolve, reject) => {
      reject(err.response.data)
    })
  }
)

axiosInstanceCRMV2.interceptors.request.use(
  (config) => {
    const userToken = localStorage.getItem('acess_token') || ('refresh_token')
    if (!userToken) {
      localStorage.removeItem('acess_token' && 'refresh_token')
    }
    const currentUser = localStorage.getItem('access_token') || ''

    if (currentUser) {
      config.headers.Authorization = `Bearer ${currentUser}`
      config.headers['x-bodytech-company'] =
        localStorage.getItem('company_id') ||
        '006629fa-6aae-4c02-b28f-5264387199bb'
      config.headers['x-bodytech-brand'] =
        localStorage.getItem('brand_id') || 1
      config.headers['x-bodytech-organization'] =
        localStorage.getItem('organization_id')
      config.headers['X-Bodytech-Virtual'] =
        localStorage.getItem('is_virtual') || 0
    }
    return config
  },
  (error) => Promise.reject(error)
)

axiosInstanceCRMV2.interceptors.response.use(
  (res) => res,
  (err) => {
    return new Promise((resolve, reject) => {
      reject(err.response.data)
    })
  }
)

axiosInstanceHarvest.interceptors.response.use(
  (res) => res,
  (err) => {
    return new Promise((resolve, reject) => {
      reject(err.response.data)
    })
  }
)

export function fetchService (axiosInstance, serviceId, parameters = {}) {
  const newServiceId = serviceId + (serviceId.includes('?') ? '&' : '?') + toQueryString(parameters)
  const response = axiosInstance.get(newServiceId).then(response => response.data)
  return response
}

export const instanceProductsGet = (serviceId, params) => {
  return fetchService(axiosInstanceProducts, serviceId, params)
}

export const instanceNotificationsGet = (serviceId, params) => {
  return fetchService(axiosInstanceNotifications, serviceId, params)
}

export const instanceGeneralConfigGet = (serviceId, params) => {
  return fetchService(axiosInstanceGeneralConfig, serviceId, params)
}

export const apiInstances = {
  products: {
    get: instanceProductsGet
  },
  notifications: {
    get: instanceNotificationsGet
  },
  generalConfig: {
    get: instanceGeneralConfigGet
  }
}
